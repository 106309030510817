.button {
  display: flex;
  align-items: center;
  justify-content: space-between;

  gap: 0.35rem;

  padding: var(--spacing-base) var(--spacing-base);

  border-radius: var(--radius-5);

  transition: 0.25s background ease, 0.25s color ease;
}

/* Primary */
.button-primary {
  background: hsl(var(--clr-foreground));
  color: hsl(var(--clr-background));
}

.button-primary:hover {
  background: hsla(var(--clr-foreground), 0.85);
}

/* Secondary */
.button-secondary {
  background: transparent;
  color: hsl(var(--clr-foreground));
}

.button-secondary:hover {
  background: hsl(var(--clr-muted));
}
