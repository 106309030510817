.landing-page {
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 0 auto;

  padding-inline: var(--spacing-base);
}
