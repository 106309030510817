.hero {
  height: 50svh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-block: var(--spacing-base);
}

.hero-main {
  max-width: 720px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;
}

.hero-main--greetings {
  font-size: var(--font-size-xxl);
}

.hero-main--greetings--blur {
  color: hsla(var(--clr-foreground), 0.5);
}

.hero-main--summary {
  font-size: var(--font-size-base);
  color: hsla(var(--clr-foreground), 0.7);
}

.hero-cta {
  display: flex;
  margin-top: var(--spacing-base);
}

/* Responsiveness */
/* Small devices (Phones, less than 768px) */
@media screen and (max-width: 767px) {
  /* Styles for small screens */

  .hero-main--greetings {
    font-size: var(--font-size-lg);
  }

  .hero-main--summary {
    font-size: var(--font-size-base);
  }
}
