.footer {
  width: 100%;
  height: 60px;

  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  color: hsla(var(--clr-foreground), 0.5);
}
