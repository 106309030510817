.header {
  position: sticky;
  top: var(--spacing-base);

  height: 60px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-inline: var(--spacing-2);

  background: var(--bg-semi-transparent);
  backdrop-filter: var(--blur-lg);
  border-radius: var(--radius-5);

  z-index: var(--z-idx-3);
}

.header--brand {
  font-size: var(--font-size-lg);
  font-weight: bold;
}

@media screen and (max-width: 767px) {
  /* Styles for small screens */
  .header--brand {
    font-size: var(--font-size-base);
  }
}
